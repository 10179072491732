import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

import type { ContentfulRichText } from 'utils/richTextParser';

const timeToRead = (content: ContentfulRichText) => {
  const wordsPerMin = 300;
  const parsedRaw = content?.raw && JSON.parse(content.raw);

  const data = documentToPlainTextString(parsedRaw);

  const minutes = data && Math.ceil(data.split(' ').length / wordsPerMin);

  const readingValue = minutes;

  return readingValue;
};

export default timeToRead;
