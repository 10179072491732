import { contentfulProps } from 'assets/mockData/emptyTypes';

import ComponentCardDeck from 'components/CardDeck/ResourceCardDeck';

export const getComponentProps = props => {
  const {
    contentful_id,
    __typename,
    title,
    tableOfContents,
    cardsHeading,
    displayBreadcrumbs,
    resourceType,
    blogType,
  } = props || {};
  const getConversionPanelProps = (idPrefix = '') => ({
    id: `conversion-panel-${idPrefix.toLowerCase()}`,
  });

  const getHeroProps = (breadcrumbLabel = '', urlLabel = '') => ({
    breadcrumbs: [
      { label: 'Resources', link: '/resources' },
      { label: breadcrumbLabel, link: `/resources/${urlLabel}` },
    ],
    displayBreadcrumbs,
    ...props,
  });

  const getRelatedPostsProps = (id = '', pageType = '') => ({
    id,
    headingProps: cardsHeading,
    CardDeckComponent: pageType && (
      <ComponentCardDeck
        currentPostId={contentful_id}
        pageType={pageType}
        {...contentfulProps}
        backgroundIsDark={false}
      />
    ),
  });

  const getSidebarProps = () => ({
    socialShareProps: { title },
    ...(tableOfContents && {
      tocProps: {
        tableOfContents: tableOfContents as any[],
        ...(__typename === 'ContentfulTemplateCaseStudy' && { heading: 'In this Case Study' }),
      },
      ...(['ContentfulTemplateTipsAndTools', 'ContentfulTemplateCaseStudy'].includes(__typename) && {
        subscribeFormProps: {
          heading: 'Get FieldRoutes updates in your inbox',
        },
      }),
    }),
  });

  switch (__typename) {
    case 'ContentfulTemplateVideos': {
      const breadcrumbLabel = resourceType === 'Video' ? 'Videos' : 'Webinars';
      const urlLabel = resourceType === 'Video' ? 'videos' : 'webinars';

      return {
        heroProps: getHeroProps(breadcrumbLabel, urlLabel),
        relatedPostsProps: getRelatedPostsProps('card-deck-video', breadcrumbLabel),
        conversionPanelProps: getConversionPanelProps('video'),
      };
    }
    case 'ContentfulTemplateTipsAndTools': {
      return {
        heroProps: getHeroProps('Tips and Tools', 'tips-and-tools'),
        sidebarProps: getSidebarProps(),
        relatedPostsProps: getRelatedPostsProps('card-deck-tipstool', 'Tips and Tools'),
        conversionPanelProps: getConversionPanelProps('tipstool'),
      };
    }
    case 'ContentfulTemplateEguides': {
      return {
        heroProps: getHeroProps('Eguides', 'eguides'),
        sidebarProps: getSidebarProps(),
        relatedPostsProps: getRelatedPostsProps('card-deck-eguide', 'Eguides'),
        conversionPanelProps: getConversionPanelProps('eguides'),
      };
    }
    case 'ContentfulTemplateCaseStudy': {
      return {
        heroProps: getHeroProps('Case Studies', 'case-studies'),
        sidebarProps: getSidebarProps(),
        relatedPostsProps: getRelatedPostsProps('card-deck-case-study', 'Case Studies'),
        conversionPanelProps: getConversionPanelProps('case-study'),
      };
    }
    case 'ContentfulTemplateResource': {
      return {
        heroProps:
          resourceType === 'Infographics'
            ? getHeroProps('Infographics', 'infographics')
            : getHeroProps('Reports', 'reports'),
        sidebarProps: getSidebarProps(),
        relatedPostsProps: getRelatedPostsProps(
          'card-deck-infographics',
          resourceType === 'Infographics' ? 'Infographics' : 'Reports',
        ),
        conversionPanelProps: getConversionPanelProps(resourceType === 'Infographics' ? 'infographics' : 'report'),
      };
    }
    case 'ContentfulTemplatePodcast': {
      const { publishDate, nextOne, season } = props || {};
      const nextPost = nextOne?.nodes?.filter(item => item?.season === season && publishDate < item?.publishDate)?.[0];
      const prevPost = nextOne?.nodes?.filter(item => item?.season === season && publishDate > item?.publishDate)?.[0];
      const relatedPost = nextPost || prevPost;

      return {
        heroProps: getHeroProps('Podcast', 'podcast'),
        sidebarProps: { ...getSidebarProps(), nextPodcastProps: { relatedPost } },
        relatedPostsProps: getRelatedPostsProps('card-deck-podcast', 'Podcast'),
        conversionPanelProps: getConversionPanelProps('podcast'),
      };
    }
    case 'ContentfulTemplateBlog': {
      return {
        heroProps: {
          breadcrumbs: [
            {
              label: blogType === 'News' ? 'News' : 'Blog',
              link: blogType === 'News' ? '/company/news' : '/blog',
            },
          ],
          ...props,
        },
        sidebarProps: getSidebarProps(),
        relatedPostsProps: getRelatedPostsProps('card-deck-blog', blogType === 'News' ? 'News' : 'Blog'),
        conversionPanelProps: getConversionPanelProps('blog'),
      };
    }
    default:
      break;
  }
};
