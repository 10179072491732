import {
  ContentfulComponentForm,
  ContentfulComponentHeading,
  ContentfulTemplateBlog,
  ContentfulTemplateCaseStudy,
  ContentfulTemplateEguides,
  ContentfulTemplatePodcast,
  ContentfulTemplateResource,
  ContentfulTemplateTipsAndTools,
  ContentfulTemplateVideos,
} from 'graphqlTypes';
import { FC } from 'react';

import Flex from 'quarks/Flex';

import Section from 'molecules/Section/Section';

import Body from 'components/ResourceDetailLayout/components/Body';
import ConversionPanel from 'components/ResourceDetailLayout/components/ConversionPanel';
import HeroColumn from 'components/ResourceDetailLayout/components/HeroColumn';
import HeroRow from 'components/ResourceDetailLayout/components/HeroRow';
import RelatedPosts from 'components/ResourceDetailLayout/components/RelatedPosts';
import Sidebar from 'components/ResourceDetailLayout/components/Sidebar/Sidebar';
import { getComponentProps } from 'components/ResourceDetailLayout/components/util';
import ComponentSwitchback from 'components/Switchback/Switchback';

type ResourceDetailLayoutProps = (
  | ContentfulTemplateBlog
  | ContentfulTemplateCaseStudy
  | ContentfulTemplateResource
  | ContentfulTemplatePodcast
  | ContentfulTemplateTipsAndTools
  | ContentfulTemplateEguides
  | ContentfulTemplateVideos
) & {
  cardsHeading?: ContentfulComponentHeading;
  form?: ContentfulComponentForm;
};

const ResourceDetailLayout: FC<ResourceDetailLayoutProps> = props => {
  const { heroProps, sidebarProps, relatedPostsProps, conversionPanelProps } = getComponentProps(props) || {};
  const { body, aboutTheShowSwitchback, id, __typename } = props;

  const Hero =
    __typename &&
    ['ContentfulTemplateCaseStudy', 'ContentfulTemplateResource', 'ContentfulTemplatePodcast'].includes(__typename)
      ? HeroRow
      : HeroColumn;

  return (
    <Flex flexDirection="column">
      <Hero {...heroProps} />
      {body && (
        <Section
          id={id}
          paddingTopDesktop="96"
          paddingBottomDesktop="96"
          paddingTopTablet="64"
          paddingBottomTablet="64"
          paddingTopMobile="48"
          paddingBottomMobile="48"
        >
          <Flex
            flexDirection="column-reverse"
            justifyContent="space-between"
            width="100%"
            gap="32px"
            lg={{ flexDirection: 'row' }}
          >
            <Body body={body} author={heroProps.author} type={__typename} />
            {sidebarProps && <Sidebar {...sidebarProps} />}
          </Flex>
        </Section>
      )}
      <RelatedPosts {...relatedPostsProps} />
      {__typename === 'ContentfulTemplatePodcast' && (
        <Section id="switchback-podcast" backgroundColor="Gray 50">
          <ComponentSwitchback backgroundIsDark={false} {...aboutTheShowSwitchback} />
        </Section>
      )}
      <ConversionPanel {...conversionPanelProps} />
    </Flex>
  );
};
export default ResourceDetailLayout;
