import { PageProps, graphql } from 'gatsby';
import Layout from 'layouts/Layout';

import ResourceDetailLayout from 'components/ResourceDetailLayout';

import type { ContentfulComponentForm, ContentfulComponentHeading, ContentfulTemplateCaseStudy } from 'graphqlTypes';
import type { FC } from 'react';

interface CaseStudyDataType {
  caseData: ContentfulTemplateCaseStudy;
  cardsHeading: ContentfulComponentHeading;
  form: ContentfulComponentForm;
}

const Page: FC<PageProps<CaseStudyDataType>> = ({ data: { caseData, cardsHeading, form }, location }) => (
  <Layout slug={location.pathname} seo={caseData.seo}>
    <ResourceDetailLayout
      {...caseData}
      cardsHeading={cardsHeading}
      displayBreadcrumbs={caseData.seo?.displayBreadcrumbs}
      form={form}
    />
  </Layout>
);

export default Page;

export const pageQuery = graphql`
  query templateCaseStudyQuery($slug: String!) {
    caseData: contentfulTemplateCaseStudy(slug: { eq: $slug }) {
      ...contentfulTemplateCaseStudy
    }
    cardsHeading: contentfulComponentHeading(contentful_id: { eq: "3n4ngmPpsQ3R2tKiP0IvA0" }) {
      ...contentfulComponentHeading
    }
    form: contentfulComponentForm(contentful_id: { eq: "4n7LmBwc0WKJSeBRzG3APW" }) {
      ...contentfulComponentForm
    }
  }
`;
