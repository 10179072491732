import { graphql, useStaticQuery } from 'gatsby';
import { FC } from 'react';

import Section from 'molecules/Section/Section';

import ComponentConversionPanel from 'components/ConversionPanel/ConversionPanel';

import type { ContentfulLayoutSection } from 'graphqlTypes';

interface ConversionPanelProps
  extends Omit<ContentfulLayoutSection, 'id' | 'children' | 'node_locale' | 'contentful_id' | 'internal'> {
  id?: string;
}

const ConversionPanel: FC<ConversionPanelProps> = ({ id, ...props }) => {
  const { conversionPanel } = useStaticQuery(graphql`
    query getConversionPanel {
      conversionPanel: contentfulComponentConversionPanel(contentful_id: { eq: "5I4iBj0TIvWmjCELmHVbp7" }) {
        ...contentfulComponentConversionPanel
      }
    }
  `);

  return (
    <Section id={id} {...props}>
      <ComponentConversionPanel backgroundIsDark={false} {...conversionPanel} />
    </Section>
  );
};

export default ConversionPanel;
