import { ContentfulComponentHeading, Maybe } from 'graphqlTypes';
import { FC, ReactNode } from 'react';

import { contentfulProps } from 'assets/mockData/emptyTypes';

import Section from 'molecules/Section/Section';

import ComponentHeading from 'components/Heading/Heading';

type RelatedPosts = {
  id?: string;
  headingProps?: Maybe<ContentfulComponentHeading>;
  CardDeckComponent?: ReactNode;
};

const RelatedPosts: FC<RelatedPosts> = ({ id, headingProps, CardDeckComponent }) => (
  <>
    <Section
      id={id}
      backgroundColor="Gray 50"
      paddingTopDesktop="64"
      paddingBottomDesktop="32"
      paddingTopTablet="48"
      paddingBottomTablet="32"
      paddingTopMobile="48"
      paddingBottomMobile="24"
    >
      <ComponentHeading {...contentfulProps} backgroundIsDark={false} {...headingProps} />
    </Section>
    <Section
      id={id}
      backgroundColor="Gray 50"
      paddingTopDesktop="32"
      paddingBottomDesktop="32"
      paddingTopTablet="32"
      paddingBottomTablet="32"
      paddingTopMobile="24"
      paddingBottomMobile="24"
    >
      {CardDeckComponent}
    </Section>
  </>
);

export default RelatedPosts;
